body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a.rrd-nav-link {
  text-decoration: none;
}

/* https://fkhadra.github.io/react-toastify/how-to-style */
.Toastify__toast-container {
  z-index: 100000;
  padding: 1rem !important;
  margin-top: env(safe-area-inset-top, 1rem) !important;
  border-bottom-left-radius: .5rem !important;
  border-bottom-right-radius: .5rem !important;
  border-top-left-radius: .5rem !important;
  border-top-right-radius: .5rem !important;
  border-radius: .5rem !important;
}

.Toastify__toast {
  border-radius: .5rem !important;
}
.Toastify__toast--error {
  background: linear-gradient(0.25turn, #f50e0b, #a2000a);
}

.Toastify__progress-bar {
  background: linear-gradient(0.25turn, #f50e0b, #a2000a);
}
.Toastify__progress-bar--error {
  background: linear-gradient(0.25turn, #bbbbbb, #666666);
}
