.gm-style .gm-style-iw-c {
  border-radius: 1rem;
  padding: 1rem !important;
}

.gm-style .gm-style-iw-c button {
  top: 0 !important;
  right: 0 !important;
}

.gm-style .gm-style-iw-d {
  display: flex;
  flex-direction: column;
  place-content: center;
  overflow: auto !important;
}

.DriverInfoWindow {
  color: #000000;
  opacity: 0.75;
  padding: 12;
  text-align: left;
}

.DriverInfoWindowTitle {
  font-weight: bold;
  font-size: 1.5rem;
}

.DriverInfoWindowID {
  margin-bottom: 1rem;
}

.DriverInfoWindowSectionTitle {
  font-weight: bold;
  font-size: 1.2rem;
  margin: 1rem 0;
}
